<template>
    <div class="status-wrapper" :style="{ width }">
        <b-dropdown 
            no-caret 
            class="status-button" 
            :text="`${prefix || ''} ${status?.label}`" 
            :disabled="isDisabled"
            :style="{ width }"
        >
            <b-dropdown-item v-for="(status, index) in filteredStatuses()" :key="index" variant="link" @click="toStatus(status)">
                {{ status.label }}
            </b-dropdown-item>
        </b-dropdown>
        <Icon :tooltip="!isDisabled ? nextStatusTooltip() : ''" placement="top">
            <b-button class="next-status-button" :disabled="isDisabled" @click="!isDisabled && toNextStatus()">
                <ArrowIcon class="icon" />
            </b-button>
        </Icon>
    </div>
</template>
<script>
    export default {
        props: {
            value: { validate: v => true },
            prefix: String,
            statuses: { type: Array, required: true },
            disabled: { type: Boolean, default: false },
            width: { type: String, default: 'auto' }
        },
        components: {
            Icon: () => import('@/components/General/Icon'),
            ArrowIcon: () => import('@/assets/icons/chevron-right.svg')
        },
        computed: {
            isDisabled() {
                return this.disabled || this.disabledWhenHiddenOption
            }
        },
        data() {
            return {
                status: null,
                disabledWhenHiddenOption: false
            }
        },
        mounted() {
            this.status = this.statuses.find(status => status.value === this.value) || this.statuses[0]
            if (this.status.hidden) {
                this.disabledWhenHiddenOption = true
            }
        },
        methods: {
            filteredStatuses() {
                return this.statuses.filter(el => !el.hidden)
            },
            nextStatusTooltip() {
                const statuses = this.filteredStatuses()
                return `Próximo status (${(statuses[statuses.indexOf(this.status) + 1] || statuses[0])?.label})`
            },
            toNextStatus() {
                const statuses = this.filteredStatuses()
                this.status = statuses[statuses.indexOf(this.status) + 1] || statuses[0]
                this.emitStatus()
            },
            toStatus(status) {
                this.status = status
                this.emitStatus()
            },
            emitStatus() {
                this.$emit('input', this.status.value)
                this.$emit('change', this.status.value)
            }
        }
    }
</script>
<style lang="scss" scoped>
.status-wrapper {
    height: 38px;
    display: inline-flex;
    align-items: center;
    gap: 1px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.status-button {
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0 0 8px;
    white-space: nowrap;

    ::v-deep button {
        height: 38px;
        color: white;
        font-size: 0.8rem;
        font-weight: 700;
        background-color: var(--blue-500) !important;
        border-color: transparent !important;
        border-radius: 8px 0 0 8px !important;
    }
}
.next-status-button {
    height: 38px;
    border-radius: 0 8px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    border-color: transparent !important;
    background-color: var(--blue-500);
    cursor: pointer;
}
.icon {
    width: 16px;
    stroke: white;
}
.options-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
</style>